import axios from "axios";

export const addPropertyLink = async (linkUrl: string, session: string) => {
    interface AddLink {}
    return axios.post<AddLink>(
        '/api/v1/addlink',
        {
            link: linkUrl
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${session}` 
          },
        },
    );
}