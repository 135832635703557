import { Fab, SxProps } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import React, {useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import { AddLinkDialog } from "../components/portfolio/AddLinkDialog";
import { useCookies } from "react-cookie";
import { addPropertyLink } from "../services/api.cityladder";

export const Portfolio = () =>  {
    const [open, setOpen] = React.useState(false);
    const [searchParams] = useSearchParams();
    const [cookies,,] = useCookies(['sess'])

    useEffect(() => {
        const link = searchParams.get("add");

        if(link) {
            addPropertyLink(link, cookies?.sess);
        }

    }, [searchParams, cookies]);

    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
    };

    return (
        <>
            <AddLinkDialog open={open} close={() => setOpen(false)} />

            <Fab sx={fabStyle as SxProps} aria-label="Add" color={'primary' as 'primary'} onClick={() => setOpen(true)}>
                <AddIcon />
            </Fab>
        </>
    )
}