import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import { useCookies } from "react-cookie";
import { addPropertyLink } from "../../services/api.cityladder";

export const AddLinkDialog = ({open, close} : {open: boolean, close: () => void}) => {
    const [link, setLink] = React.useState("");
    const [error, setError] = React.useState(false);
    const [cookies,,] = useCookies(['sess'])

    function valid(): boolean {
        
        const linkUrl = URL.canParse(link) && new URL(link)
        if (linkUrl) {
            switch (linkUrl.hostname) {
                case "www.zoopla.co.uk": {
                    if (linkUrl.pathname.startsWith('/for-sale/details/')) {
                        setError(false)
                        return true
                    }
                    break
                }
                case "www.rightmove.co.uk": {
                    if (linkUrl.pathname.startsWith('/properties')) {
                        setError(false)
                        return true
                    }
                    break
                }
                
            }
        }
        setError(true)
        return false
    }

    async function addLink() {
        if(valid()) {
            await addPropertyLink(link, cookies?.sess);
            close()
        }
    }
    
    return (
        <Dialog open={open}>
            <DialogTitle>
                Add property
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                Add a property from a portal link such Zopa or Rightmove.
            </DialogContentText>
            <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                error={error}
                label="Link"
                type="text"
                fullWidth
                variant="standard"
                helperText="Please use a Zopla or Rightmove link"
                onChange={event => setLink(event.target.value)}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button type="submit" onClick={addLink}>Add</Button>
            </DialogActions>
        </Dialog>
        )
}